import { definePreset } from '@primevue/themes'
import Aura from '@primevue/themes/aura'

export default definePreset(Aura, {
    semantic: {
        primary: {
            50:  '{violet.50}',
            100: '{violet.100}',
            200: '{violet.200}',
            300: '{violet.300}',
            400: '{violet.400}',
            500: '{violet.500}',
            600: '{violet.600}',
            700: '{violet.700}',
            800: '{violet.800}',
            900: '{violet.900}',
            950: '{violet.950}'
        },
        colorScheme: {
            light: {
                surface: {
                    0:   '#ffffff',
                    50:  '{zinc.50}',
                    100: '{zinc.100}',
                    200: '{zinc.200}',
                    300: '{zinc.300}',
                    400: '{zinc.400}',
                    500: '{zinc.500}',
                    600: '{zinc.600}',
                    700: '{zinc.700}',
                    800: '{zinc.800}',
                    900: '{zinc.900}',
                    950: '{zinc.950}'
                }
            },
            dark: {
                surface: {
                    0:   '#ffffff',
                    50:  '{zinc.50}',
                    100: '{zinc.100}',
                    200: '{zinc.200}',
                    300: '{zinc.300}',
                    400: '{zinc.400}',
                    500: '{zinc.500}',
                    600: '{zinc.600}',
                    700: '{zinc.700}',
                    800: '{zinc.800}',
                    900: '{zinc.900}',
                    950: '{zinc.950}'
                }
            },
        },
        anchorGutter: '4px',
    },
    components: {
        button: {
            sm: {
                'paddingX': '.25rem',
                'paddingY': '.25rem',
            }
        },
        contextmenu: {
            'item.icon.size': '.875rem',
        },
        dialog: {
            'border.radius': '.5rem',
        },
        menu: {
            'border.radius': '.25rem',
        },
        tabs: {
            'tab.padding': '.5rem 1rem',
        },
        toast: {
            'width': '20rem',
            'icon.size': '.98rem',
            'content.padding': '.66rem',
            'summary.font.size': '.875rem',
            'detail.font.size': '.825rem',
            'close.icon.size': '.875rem',
        },
        toggleswitch: {
            'width': '2.25rem',
            'height': '1.25rem',
            'handle.size': '.75rem',
            'colorScheme': {
                'light': {
                    'checked.background': '{surface.500}',
                    'checked.hover.background': '{surface.300}',
                },
                'dark': {
                    'checked.background': '{surface.400}',
                    'checked.hover.background': '{surface.500}',
                },
            }
        },
    }
})
