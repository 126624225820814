import AppLayout from '@/Components/AppLayout.vue'
//import { useUserEventsConnection } from '@/Composables/useUserEventsConnection'
import { CSRF_TOKEN } from '@/injectionKeys'
import resetStore from '@/Stores/Plugins/resetStore'
import { useRoomStore } from '@/Stores/RoomStore'
import BugsnagPerformance from '@bugsnag/browser-performance'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue, { type BugsnagPluginVueResult } from '@bugsnag/plugin-vue'
import { createInertiaApp, usePage } from '@inertiajs/vue3'
import Echo from 'laravel-echo'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { WaveConnector } from 'laravel-wave'
import mitt from 'mitt'
import { createPinia } from 'pinia'
import Tooltip from 'primevue/tooltip'
import { type ComputedRef, computed, createApp, h, type DefineComponent, type ComponentInstance } from 'vue'
import VueTippy from 'vue-tippy'
import { ZiggyVue } from 'ziggy-js'
import '../css/app.css'
import 'floating-vue/dist/style.css'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import PrimeVue from 'primevue/config'
import WiroomPreset from './wiroom_preset'
import ConfirmationService from 'primevue/confirmationservice'
import DialogService from 'primevue/dialogservice'
import ToastService from 'primevue/toastservice'

declare const __BUILD_DATE__: string

const hasBugsnag = !!import.meta.env.VITE_BUGSNAG_API_KEY
const isProduction = process.env.NODE_ENV === 'production'
if (isProduction && !hasBugsnag) {
    console.warn('Bugsnag API key is missing')
}

if (hasBugsnag) {
    BugsnagPerformance.start({
        apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
        releaseStage: process.env.NODE_ENV,
        enabledReleaseStages: [ 'production', 'staging' ],
    })
    Bugsnag.start({
        apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
        plugins: [new BugsnagPluginVue()],
        appType: 'app',
        appVersion: __BUILD_DATE__,
        releaseStage: process.env.NODE_ENV,
        enabledReleaseStages: [ 'production', 'staging' ],
        onError: function (event) {
            const auth = computed(() => usePage().props.auth)
            const user = auth.value?.user ?? false
            if (user) {
                event.setUser(user.id.toString(), user.email, user.full_name)
            }
        }
    })
}

// noinspection JSIgnoredPromiseFromCall
createInertiaApp({
    progress: {
        delay: 450,
    },
    resolve: (name: string) => {
        let page = resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob<DefineComponent>('./Pages/**/*.vue'))
        page.then((module: ComponentInstance<any>) => {
            module.default.layout = module.default.layout || AppLayout
        })
        return page
    },
    setup({ el, App, props, plugin }) {
        const VueApp = createApp({ render: () => h(App, props) })

        if (hasBugsnag) {
            VueApp.use(Bugsnag.getPlugin('vue') as BugsnagPluginVueResult)
        }

        const pinia = createPinia()
        pinia.use(resetStore)

        const csrfToken = <ComputedRef<string>>computed(() => usePage().props.csrf_token)
        VueApp
            .use(plugin)
            .use(PrimeVue, {
                theme: {
                    preset: WiroomPreset,
                    options: {
                        cssLayer: {
                            name: 'primevue',
                            order: 'tailwind-base, primevue, tailwind-utilities'
                        }
                    }
                },
                ripple: false,
            })
            .use(ConfirmationService)
            .use(DialogService)
            .use(ToastService)
            .use(ZiggyVue)
            .use(pinia)
            .directive('tooltip', Tooltip)
            .use(VueTippy, {
                directive: "tippy",
                defaultProps: {
                    placement: 'bottom',
                    allowHTML: true,
                    delay: [500, null],
                },
            })
            .provide(CSRF_TOKEN, csrfToken)
            .provide('notifications', props.initialPage.props.notifications)
            .provide('auth', props.initialPage.props.auth)
            .provide('emitter', mitt())
            .mount(el)

        const roomStore = useRoomStore()
        // noinspection JSValidateTypes
        roomStore.rooms = props.initialPage.props.rooms

        // open user connection / may be moved into AppLAyout ?
        //const userId = props.initialPage.props.auth.user.value?.id

        // const { openConnection } = useUserEventsConnection()
        // // open new connection if project is not null and is not the same as the previous one
        // if (userId) {
        //     // noinspection JSValidateTypes
        //     openConnection(userId)
        // }
    },
})

window.Echo = new Echo({ broadcaster: WaveConnector })
