import {
    format,
    formatDistance,
    formatDistanceToNowStrict,
    intervalToDuration,
    isToday,
} from 'date-fns'
import { enGB } from 'date-fns/locale'

export function getDistance(timestamp: string) {
    return intervalToDuration({
        start: new Date(timestamp),
        end: new Date()
    })
}

export function getIsToday(timestamp: string) {
    return isToday(new Date(timestamp))
}

export function formatTime(timestamp: string, pattern = 'dd/MM/yyyy HH:mm') {
    return format(new Date(timestamp), pattern, { locale: enGB })
}

export function formatTimeDistance(timestamp: string, baseDate: Date | null = null, options: { locale?: any, addSuffix?: boolean } = {}) {
    if (!(baseDate instanceof Date)) {
        baseDate = new Date()
    }

    return formatDistance(new Date(timestamp), baseDate, Object.assign({
        locale: enGB,
        addSuffix: false
    }, options));
}

export function formatTimeAgo(timestamp: string, options: { locale?: any, addSuffix?: boolean } = {}) {
    return formatDistanceToNowStrict(new Date(timestamp), Object.assign({
        locale: enGB,
        addSuffix: true,
    }, options));
}
